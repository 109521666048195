import React from 'react';
import { Button, Row, Col, Image } from 'react-bootstrap';

const Process = () => {
  // Puedes definir aquí los detalles de cada paso si quieres que sea dinámico
  const steps = [
    {
      number: '01.',
      image: '/images/process1.png',
      title: 'Realiza la reservación',
      text: 'Comunicate directamente llamando al 1-787-741-6032 o al 954-995-8752 para realizar tu reserva.'
      // otros detalles si son necesarios
    },
    {
      number: '02.',
      image: '/images/process2.png',
      title: 'Completa los requisitos',
      text: 'Asegúrate de tener todos los documentos y trámites en orden para garantizar un proceso de embarque sin contratiempos.'
      // otros detalles si son necesarios
    },
    {
      number: '03.',
      image: '/images/process3.png',
      title: 'Prepara tu vehículo',
      text: 'De acuerdo con las restricciones e indicaciones para que pueda ser transportado de manera segura a su destino'
      // otros detalles si son necesarios
    },
    {
      number: '04.',
      image: '/images/process4.png',
      title: 'Recibe tu vehículo en el destino',
      text: 'Una vez completado el proceso de envío, asegúrate de estar presente para recibir tu vehículo en el destino designado.'
      // otros detalles si son necesarios
    },
  ];




  return (
    <div id='process' className='d-none d-md-block'>
    <Row className=" text-poppins g-0">
      <Col xs={12} md={6} className=''>
       
        <h1  className='process-tittle'>Conoce nuestro <span className='fw-bold'>proceso de envío</span></h1>
        <Button variant="primary" className="mt-3 button-process" >Ver proceso detallado</Button>
      
      </Col>
      <Col xs={12} md={6}>
        {steps.map((step, index) => (
          <Row key={index} className="mb-4 align-items-center mt-5 px-5 g-0">
            <Col xs={2} className="text-end fw-bold">
             <h1 className='d-flex align-items-start'> {step.number}</h1>
            </Col>
            <Col xs={4} >
              <Image src={step.image} fluid />
            </Col>
            <Col xs={6} className=''>
            <h3 className='text-poppins fw-bold'>{step.title}</h3>
              {step.text}
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
</div>

  );
};

export default Process;
