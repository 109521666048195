import React from 'react';
import {Row, Col, Image } from 'react-bootstrap';

function Info() {
  const imageStyle = {
    maxWidth: '70%', // Usamos el 100% del ancho de la columna
    height: 'auto',
    borderRadius: '5px',
  
  };

  const textStyle = {
    backgroundColor: 'white',
    borderRadius: '10px',
    margin: '10px',
    padding: '10px',
    display: 'flex', // Usando flexbox para alinear lado a lado
    alignItems: 'center', // Alinea verticalmente el texto con la imagen
  };
  return (
    <div fluid className="text-poppins d-none d-md-block" style={{ backgroundColor: '#CF2437', borderRadius: '20px', padding: '20px', color: 'white' }} id='info'>
      <Row className="justify-content-center ">
       
        <Col md={6} className="d-flex flex-column ">
          <h1 className="fw-bold px-5 mt-5 mb-3" style={{fontSize: '3.7rem'}}>¿Por qué PRAutotransport?</h1>
          <p className="px-5" style={{fontSize:'1.5rem'}}>Descubre por qué somos la opción preferida para tus necesidades de transporte y gestión vehicular.</p>
          </Col>
          <Col>
          <div style={textStyle} className="w-70">
          <Image src="/images/info3.png" style={imageStyle} />
            <div>
            <h4 className='text-black fw-bold mt-2'>Mejor precio garantizado</h4>
            <p className='text-black'>Cotiza el envío de tu carro sin compromiso, te garantizamos el mejor precio del mercado. Si tienes un precio más bajo, lo igualamos.</p>
            
            </div>
          </div>
        </Col>
        </Row>
        <Row>
        <Col md={6} className="d-flex flex-column align-items-center">
        <div style={textStyle} className="w-70">
          <Image src="/images/info1.png" style={imageStyle} />
            <div>
            <h4 className='text-black fw-bold mt-2'>Servicio integral</h4>
            <p className='text-black'>Oficinas en Puerto Rico y USA. Brindamos servicios de Affidavit, lavado de autos, gestorias y servicio de puerta a puerta.</p>
            
            </div>
          </div>
          </Col>
          <Col md={6}>
          <div style={textStyle} className="w-70">
          <Image src="/images/info4.png" style={imageStyle} />
            <div>
            <h4 className='text-black fw-bold '>Servicio personalizado</h4>
            <p className='text-black'>Brindamos un servicio completamente personalizado, un asesor te acompañara 24/7 hasta que recibas tu carro en el destino final.</p>
            
            </div>
          </div>
          </Col>
          </Row>
          <Row>
          <Col md={6}>
          <div style={textStyle} className="w-70">
          <Image src="/images/info2.png" style={imageStyle} />
            <div>
            <h4 className='text-black fw-bold '>Asistencia documental</h4>
            <p className='text-black'>Realiza con nosotros los documentos para el envío de tu carro con seguridad y tranquilidad.</p>
            
            </div>
          </div>
          </Col>
          <Col md={6}>
          <div style={textStyle} className="w-70">
            <Image src="/images/info5.png" style={imageStyle} />
            <div >
            <h4 className='text-black fw-bold mt-2'>Rápido y seguro</h4>
            <p className='text-black'>Ofrecemos un servicio rápido con salidas programadas todas las semanas para garantizar la tranquilidad en tus envíos</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Info;
