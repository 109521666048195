import React from 'react';
import { Button, Row, Col, Image } from 'react-bootstrap';

const ProcessMovil = () => {
  const steps = [
    {
      number: '01.',
      image: '/images/process1.png',
      title: 'Realiza la reservación',
      text: 'Comunicate directamente llamando al 1-787-741-6032 o al 954-995-8752 para realizar tu reserva.'
    },
    {
      number: '02.',
      image: '/images/process2.png',
      title: 'Completa los requisitos',
      text: 'Asegúrate de tener todos los documentos y trámites en orden para garantizar un proceso de embarque sin contratiempos.'
    },
    {
      number: '03.',
      image: '/images/process3.png',
      title: 'Prepara tu vehículo',
      text: 'De acuerdo con las restricciones e indicaciones para que pueda ser transportado de manera segura a su destino'
    },
    {
      number: '04.',
      image: '/images/process4.png',
      title: 'Recibe tu vehículo en el destino',
      text: 'Una vez completado el proceso de envío, asegúrate de estar presente para recibir tu vehículo en el destino designado.'
    },
  ];

  return (
    <div className="text-poppins d-block d-md-none" id='process'>
      <Row className="g-0 justify-content-center">
        <Col xs={12} className="text-center my-3">
        <h1  className='process-tittle my-3 px-3 '>Conoce nuestro <span className='fw-bold'>proceso de envío</span></h1>
          <Button variant="primary" className="button-process2 mt-2" >Ver proceso detallado</Button>
        </Col>
        {steps.map((step, index) => (
          <Col xs={12} key={index} className="mb-4 px-4">
            <div className="text-center">
              <h2 className="fw-bold">{step.number}</h2>
              <Image src={step.image} alt={step.title} fluid className="mb-3" />
              <h3 className='fw-bold'>{step.title}</h3>
              <p>{step.text}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ProcessMovil;