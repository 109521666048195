import React from 'react';
import { Row, Col, Button, Image, Form } from 'react-bootstrap';


const Documents= () => {
    return (
      <div id='documents'>
        <Row className='my-5 g-0 text-poppins'>
          {/* Columna de navegación */}
          <Col xs={12} md={3} className='align-items-center d-none d-md-block' >
            <div className="navigation-links ms-5 mt-5">
              <h3 className='mb-5'>Procedimientos</h3>
              <a href="#requisitos" className="nav-link mb-2">Requisitos</a>
              <a href="#permitidos" className="nav-link mb-2">No permitidos</a>
              <a href="#informacion" className="nav-link mb-2">Mas informacion</a>
            </div>
          </Col>

          <Col xs={12} md={9} className='g-0 px-5' style={{ borderLeft: '1px solid #000' }}>
          <h1 className='text-center fw-bold'>Enviando tu auto con PRautotransport</h1>
          <h5 className='fst-italic my-4 ms-3'>La reservación es requerida antes de entregar tu vehículo. h3ara realizar una reservación por favor llamar al 1-787-741-6032 o al 954-995-8752</h5>
          <h4 className='align-items-center ms-3 fw-bold' id='requisitos'>Requisitos</h4>
          <br></br>
          <p className='ms-3'>Los siguientes requisitos tienen que ser completados antes de embarcar un vehículo de motor:</p>
        
          <p className='ms-3'>1. Documentos del vehículo:</p>
        
          <p className='ms-3'><span className='fw-bold'>*</span>Licencia (registración)</p>
      
          <p className='ms-3'><span className='fw-bold'>*</span>Título del vehículo </p>
       
          <p className='ms-3'><span className='fw-bold'>*</span>Copia de licencia de conducir del dueño</p>
          <p className='ms-3'>2.Certificado de no multas pendientes de pago con sellos de $1.50 Emitido por el Departamento de Transportación y Obras Públicas (DTOP)  vigente por 2 días. Impresión de Internet no es válido. </p>
          <p className='ms-3'>3.Comprobante (voucher) de exportación por $10.00 de la Colecturía (No sellos/stamps).</p>
          <p className='ms-3'>4.El vehículo tiene que ser inspeccionado en la División de Vehículos Hurtados (Estación de policía de la ciudad donde reside el vehículo). (Válido por 5 días solamente)</p>
          <p className='ms-3'>5.Certificado de no deudas con ACCA (La administración que se ocupa de autos accidentes) *Emitido en la oficinas de San Juan, PR solamente. (Arterial Hostos, Esq. Chardón -San Juan, PR 00936).</p>
          <p className='ms-3'>6.Recibo de lavado incluyendo el exterior, motor y chasis</p>
          <p className='ms-3'>7.Declaración jurada (original) que autoriza a Nomanejes.com/PRautotransport.com y el puerto hacer el envío. (Documento original con sello de Puerto Rico).</p>
          <p className='ms-3'>8.Declaración jurada (original) hecha en Puerto Rico más copia de la licencia del dueño autorizando el embarque. (Es requerido cuando el dueño no esté presente).</p>
          <p className='ms-3'>9.Carta del banco (original) autorizando el embarque en caso de tener algún gravamen (ver ejemplo).</p>
          <p className='ms-3'>10.Si el envío del vehículo es hacia Puerto Rico, usted es responsable de pagar el impuesto de importación del vehículo. Para determinar el requisito del impuesto estimado para el vehículo específico, por favor tenga su VIN listo y haga click <a href='https://siscon.hacienda.gobierno.pr/Siscon/Portal/ARBAW951Form.aspx'>aquí</a></p>
          <h5 className='ms-3'>Todas las tablillas de Puerto Rico serán removidas por la policia antes de embarcación y no serán devueltas</h5>
            <br></br>
          <h5 className='ms-3 fst-italic px-2'>AVíSANOS SI DESEAS QUE TE AYUDEMOS A REALIZAR ESTAS GESTORIAS, LLAMANDONOS O VISITANDO NUESTRAS OFICINAS DE PR EN: Ave. Boulevard, W-7, Ste. 2, Levittown, Toa Baja, PR 00949.  Horario: Lunes a Viernes 10am - 6pm  Telefonos 787-231-5526  ó 787-354-4252 </h5>
          <br></br>
          <h4 className='align-items-center ms-3 fw-bold' id='permitidos'>No es permitido</h4>

          <p className='ms-3'><span className='fw-bold'>*</span>Vehículo con alarmas que no sean instalados por el productor. (Estas tienen que ser desconectadas)</p>

          <p className='ms-3'><span className='fw-bold'>*</span>Vehículos que no tengan los sellos con los números de identificación legibles en las puertas, bonete, tapa de baúl y “dashboard”. Todas las partes reemplazadas tienen que ser documentadas.</p>

          <p className='ms-3'><span className='fw-bold'>*</span>No podemos enviar los vehículos con ventanas rotas, dañadas, o vehículos que son inoperables. (Si el vehículo deja de funcionar después de ser recibido, se cobrará un adicional de $150.00).</p>

          <p className='ms-3'><span className='fw-bold'>*</span>No se aceptará para el envío cualquier vehículo que se considere peligroso . </p>

          <p className='ms-3'><span className='fw-bold'>*</span>No aceptamos vehículos con artículos personales por dentro. Los únicos artículos aceptables son accesorios que normalmente son conectados al vehículo, o permanentemente instalados. (Aceptemos asientos de niños). </p>

          <p className='ms-3'><span className='fw-bold'>*</span>Al momento de entrega no se aceptarán vehículos que contengan más de un cuarto de gasolina en el tanque.</p>

          <p className='ms-3'><span className='fw-bold'>*</span>Por favor, tenga en cuenta que los vehículos que son de gran tamaño se envían de formas especiales dependiendo de la dimensiones (Camiones /Vans/ vehículos de gran tamaño). Para enviar alguno de estos vehículos por favor llamenos al 1-787-741-6032.</p>

          
          <Image src="/images/terms.png" alt="Descripción de la imagen" fluid />
          <div style={{backgroundColor: '#385D7F'}} className='text-center px-5' id='informacion'>
                <h3 className='mb-5 text-center text-white fw-bold'>¿Necesitas más información?</h3>
                <Form.Group controlId="formBasicEmail" className="mb-4">
        <Form.Control 
          type="email" 
          placeholder="@" 
          style={{ width: '75%', margin: '0 auto' }}  // Centra el input agregando margin auto
        />
      </Form.Group>
                <Button variant="outline-light" className='custom-buttonf mb-5' style={{justifyContent: 'center', width: '75%' }}>
                Mas Informacion
                 </Button>
          </div>


          </Col>
          </Row>
        </div>

    );
}

export default Documents;