import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import NavigationBar from './components/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import Index from './components/Index';
import Somos from './components/Somos';
import Info from './components/Info';
import InfoMovil from './components/InfoMovil';
import Process from './components/Process';
import Payment from './components/Payment';
import Review from './components/Review';
import Tiempos from './components/Tiempos';
import Footer from './components/Footer';
import MobileFooter from './components/FooterMovil';
import Cotizar from './components/Cotizar';
import Faqs from './components/Faqs';
import Terms from './components/Terms';
import Documents from './components/Documents';
import Gracias from './components/Gracias';
import ProcessMovil from './components/ProcessMovil';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.bitrix24.com/b2514537/crm/site_button/loader_8_7makj1.js?' + (Date.now() / 60000 | 0);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Router>
      <div className="App bg-navbar">
        <NavigationBar/>
        <Routes>
          <Route path="/" element={<>
            <Index/>
            <Somos/>
            <Info/>
            <InfoMovil/>
            <Process/>
            <ProcessMovil/>
            <Payment/>
            <Review/>
            <Tiempos/> 
          </>} />
          <Route path="/Index" element={<Index/>} />
          <Route path='/cotizar' element={<Cotizar/>}/>
          <Route path='/faqs' element={<Faqs/>}/>
          <Route path='/terms' element={<Terms/>}/>
          <Route path='/documents' element={<Documents/>}/>
          <Route path='/gracias' element={<Gracias/>}/>
      </Routes>
      <Footer/>
      <MobileFooter/>
    </div>
    </Router>
  );
}

export default App;
